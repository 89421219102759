import validate from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import query_45params_45global from "/opt/atlassian/pipelines/agent/build/middleware/query-params.global.ts";
import manifest_45route_45rule from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  query_45params_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/atlassian/pipelines/agent/build/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}