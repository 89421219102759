import type { RouteLocationNormalized } from "vue-router";

function hasQueryParams(route: RouteLocationNormalized) {
  return !!Object.keys(route.query).length;
}

export default defineNuxtRouteMiddleware((to, from) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    if (
      from.query.redirect &&
      to.path.endsWith(from.query.redirect as string)
    ) {
      return;
    }
    return navigateTo({ path: to.path, query: from.query });
  }
});
