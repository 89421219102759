type SnackbarTypes = "primary" | "success" | "warning" | "error" | "info";
type SnackbarAction = {
  label: string;
  action: (snackbar: SnackbarData) => void;
};
type SnackbarData = {
  id: number;
  message: string;
  type: SnackbarTypes;
  display: boolean;
  actions?: SnackbarAction[];
};

export const useSnackbarStore = defineStore("snackbar", () => {
  const snackbars = ref([] as SnackbarData[]);
  const idCounter = ref(0);

  function show(
    message: string,
    type = "primary",
    persistent = false,
    actions = [] as SnackbarAction[],
  ) {
    const id = ++idCounter.value;
    snackbars.value.push({
      id,
      message,
      actions,
      type: type as SnackbarTypes,
      display: true,
    });
    if (!persistent) {
      setTimeout(() => {
        remove(id);
      }, 7000);
    }
  }

  function remove(id: number) {
    const index = snackbars.value.findIndex((s) => s.id === id);
    snackbars.value.splice(index, 1);
  }

  function clearAll() {
    snackbars.value = [];
  }

  return {
    snackbars,
    show,
    clearAll,
  };
});
