<script setup lang="ts">
import { useSnackbarStore } from "~/store/useSnackbarStore";

const SNACKBAR_HEIGHT = 55;

const snackbarStore = useSnackbarStore();
const snackbars = computed(() => snackbarStore.snackbars);
</script>

<template>
  <div>
    <VSnackbar
      v-for="(snackbar, idx) in snackbars"
      :key="snackbar.id"
      v-model="snackbar.display"
      :text="snackbar.message"
      :color="snackbar.type"
      location="bottom"
      close-on-content-click
      :close-on-back="false"
      :timeout="-1"
      :style="{ bottom: `${SNACKBAR_HEIGHT * idx}px` }"
    >
      <template #actions>
        <VBtn
          v-for="action in snackbar.actions"
          :key="action.label"
          :text="action.label"
          :color="snackbar.type"
          variant="text"
          @click="action.action(snackbar)"
        />
      </template>
    </VSnackbar>
  </div>
</template>
