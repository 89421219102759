import {VDateInput} from 'vuetify/labs/VDateInput'
import {VTimePicker} from 'vuetify/labs/VTimePicker'

export const isDev = false
export function vuetifyConfiguration() {
  const options = {"theme":{"themes":{"light":{"colors":{"primary":"#1866c0","primary-lighten":"#1c77d2","primary-lighten-2":"#2289e5","primary-darken":"#1048a1"}}}}}
  
  
  options.components = {VDateInput,VTimePicker}
  
  return options
}

